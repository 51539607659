<template>
  <div class="row mt--lg">
    <div class="col-md-9 mt--sm">
      <getecma-p color="theme-900-100" size="md">
        <b>Grupo:</b> {{ group.name }}
      </getecma-p>
    </div>
    <div class="col-md-9 mt--sm">
      <getecma-p color="theme-900-100" size="md">
        <b>Unidade de Medida:</b> {{ capacity ? capacity.name : 'Carregando...' }}
      </getecma-p>
    </div>
    <div class="col-md-9 mt--sm">
      <getecma-p color="theme-900-100" size="md">
        <b>Clientes:</b> <span v-for="customer in customers" :key="customer.id"><br>• {{ customer.name }} </span>
      </getecma-p>
    </div>
  </div>
</template>

<script>
import { toastError } from '@/services/toastService';
import { getCapacityById } from '@/modules/capacity/capacity.service';
import { fetchCustomersByGroup } from '@/modules/group/group.service';

export default {
  name: 'GetecmaGroupViewInformation',
  props: {
    group: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      capacity: null,
      customers: null,
    };
  },
  mounted() {
    this.fetch();
  },
  methods: {
    fetch() {
      getCapacityById(this.group.capacity_id)
        .then(data => {
          this.capacity = data;
        })
        .catch(() => toastError('Erro ao obter capacidade por ID'));

      fetchCustomersByGroup(this.group.id)
        .then(data => {
          this.customers = data.rows;
        })
        .catch(() => toastError('Erro ao obter clientes por grupo'));
    },
  },
};
</script>
